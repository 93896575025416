import { useMemo, useRef, useState, useEffect } from 'react';
import { InventoryAsset } from '../../../types';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import { Icon } from '../../Atoms/Icon';
import { FilterBar } from './FilterBar';
import { InfoWindowContent } from './InfoWindowContent';
import { useNavigate } from 'react-router-dom';
import { FILTER_SERVICE_CATEGORIES, generateMapData } from '../../../utils/helpers';
import { CollapseButton } from '../../Atoms/CollapseButton';
import { MapTable } from './MapTable';
import { BORDER_STYLE } from '../../../utils/styleHelpers';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { TextBlock } from '../../Atoms/Text';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { UserPreferences, getCurrentPreferences, setPreferences } from '../../../store/User';
import { v4 } from 'uuid';
import { getConfiguration } from '../../../store/Configuration';

const center = {
  lat: 42,
  lng: -100
};

export interface MapWidgetProps {
  inventoryAssets: InventoryAsset[];
  isHome?: boolean;
  isOnlyFull?: boolean;
  handleFullClose?: () => void;
}

export function MapWidget({ inventoryAssets, isOnlyFull, handleFullClose, isHome }: MapWidgetProps) {
  const mapRef = useRef<google.maps.Map>();
  const [loadedMarkers, setLoadedMarkers] = useState<number>(0);
  const [isFullsize, setIsFullsize] = useState<boolean>(isOnlyFull || false);
  const [selectedAddress, setSelectedAddress] = useState<[string, InventoryAsset[]] | null>(null);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<string[]>(FILTER_SERVICE_CATEGORIES);
  const [collapse, setCollapse] = useState<boolean>(false);
  const navigate = useNavigate();

  const productFamilies = new Set<string>();
  inventoryAssets.forEach((asset) => {
    if (asset.product && asset.product.family && FILTER_SERVICE_CATEGORIES.includes(asset.product.family)) {
      productFamilies.add(asset.product.family);
    }
  });

  const isShowFilterBar = productFamilies.size > 1;

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: getConfiguration().REACT_APP_GOOGLE_MAPS_API_KEY });
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadingSub = getInventoryLoadingSubject().subscribe((loading) => setInventoryLoading(loading));

    return () => {
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const addresses = useMemo(
    () => generateMapData(inventoryAssets, selectedServiceTypes),
    [inventoryAssets, selectedServiceTypes]
  );

  const onLoadMarker = () => {
    setLoadedMarkers((prev) => prev + 1);
  };

  const handleMarkerClick = (assets: InventoryAsset[]) => {
    setSelectedAddress([assets?.[0]?.addressInfo?.locationName, assets]);
  };

  const markers = useMemo(
    () =>
      addresses.map((address, index) => {
        return (
          <Marker
            key={`${address?.[1]?.[0]?.addressInfo?.latitude}-${address?.[1]?.[0]?.addressInfo?.longitude}-${index}`}
            onClick={() => handleMarkerClick(address[1])}
            onLoad={onLoadMarker}
            position={{ lat: address?.[1]?.[0]?.addressInfo?.latitude, lng: address?.[1]?.[0]?.addressInfo?.longitude }}
          />
        );
      }),
    [addresses]
  );

  useEffect(() => {
    if (
      (!isFullsize || isOnlyFull) &&
      loadedMarkers === addresses.length &&
      addresses.length > 0 &&
      window.google.maps
    ) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        const position = marker.props.position;
        bounds.extend(new window.google.maps.LatLng(position?.lat, position?.lng));
      });
      mapRef.current?.fitBounds(bounds);
    }
  }, [loadedMarkers, addresses, markers]);

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const isInventory = document.getElementsByClassName('masonry-item').length == 0;
  const checkMapWidth = isInventory ? '300px' : 'auto';
  const checkMapHeight = isInventory ? '175px' : '350px';
  const mapButtons = isInventory ? 'top-[140px]' : 'top-[310px]';

  const mapcontainer = () => {
    return (
      <GoogleMap
        mapContainerStyle={{
          width: isFullsize ? '100%' : checkMapWidth,
          height: isFullsize ? '100%' : checkMapHeight
        }}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          styles: require('./mapStyles.json'),
          zoomControl: false
        }}
        center={center}
        onLoad={handleMapLoad}
        mapContainerClassName={isFullsize ? 'rounded-lg' : collapse ? '' : 'rounded-b-lg'}
        zoom={3}>
        <MarkerClusterer options={{ imagePath: '/cluster' }}>
          {(clusterer) => (
            <>
              {markers.map((marker) => (
                <Marker
                  icon="/circle.svg"
                  clusterer={clusterer}
                  key={marker.key}
                  {...marker.props}
                  onLoad={onLoadMarker}
                />
              ))}
            </>
          )}
        </MarkerClusterer>
        {isFullsize && selectedAddress && (
          <InfoWindow
            position={{
              lat: selectedAddress?.[1]?.[0]?.addressInfo?.latitude,
              lng: selectedAddress?.[1]?.[0]?.addressInfo?.longitude
            }}
            options={{ pixelOffset: new google.maps.Size(0, -15) }}
            onCloseClick={() => setSelectedAddress(null)}>
            <InfoWindowContent
              inventoryAssets={selectedAddress[1]}
              onClick={addressClick(selectedAddress[1]?.[0]?.address)}
            />
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  const handleIsFullsize = () => {
    mapRef.current = undefined;
    setSelectedAddress(null);
    isOnlyFull && handleFullClose ? handleFullClose() : setIsFullsize(!isFullsize);
  };

  const handleZoom = (diff: number) => () => {
    mapRef.current?.setZoom((mapRef.current?.getZoom() || 0) + diff);
  };

  const handleSelectedServiceTypes = (serviceType: string) => {
    if (selectedServiceTypes.includes(serviceType)) {
      setSelectedServiceTypes(selectedServiceTypes.filter((type) => type !== serviceType));
    } else {
      setSelectedServiceTypes([...selectedServiceTypes, serviceType]);
    }
    setSelectedAddress(null);
  };

  const filterAddress = async (addressName: string) => {
    const filter: GridFilterItem = {
      id: v4(),
      field: 'address',
      operator: 'is',
      value: addressName
    };

    const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));
    if (update.content.inventory_grid_All.muiConfig.filter?.filterModel) {
      update.content.inventory_grid_All.muiConfig.filter.filterModel.items = [filter];
    }

    setPreferences(update);
    if (isHome) navigate('/inventory');
  };

  const addressClick = (addressName: string) => () => {
    handleIsFullsize();
    filterAddress(addressName);
  };

  const handleAllClick = () => {
    navigate('/inventory');
  };

  return (
    <div className={`${BORDER_STYLE} pt-2 min-w-64`}>
      <div className="col-span-2 text-sm font-medium text-grey-5 text-center">LOCATIONS</div>
      {inventoryLoading && (
        <div className={`flex ${isInventory ? 'h-44' : 'h-32'} items-center justify-center`}>
          <ComponentSpinner />
        </div>
      )}
      {!inventoryLoading && !inventoryAssets?.length && (
        <div className={`flex ${isInventory ? 'h-44' : 'h-32'} items-center justify-center`}>
          <TextBlock size="sm14">No data available</TextBlock>
        </div>
      )}
      {!inventoryLoading && inventoryAssets?.length > 0 && (
        <div
          className="pt-2 relative text-[10px] map-container"
          data-cy="map-container"
          data-testid="map-container">
          {isFullsize && (
            <div
              className="fixed top-[3%] left-[3%] w-[94%] h-[94%] z-10"
              data-testid="map-fullsize">
              <button
                aria-label="map-close-button"
                className="h-[40px] w-[40px] border-[1px] border-gray-3 rounded-[5px] p-[11px] bg-white absolute right-[17px] top-[17px] z-10"
                data-cy="map-close-button"
                onClick={handleIsFullsize}>
                <Icon
                  type="cancel"
                  className="cursor-pointer h-[16px] !bg-contain"
                />
              </button>
              <button
                aria-label="map-zoom-plus-button"
                className="h-[40px] w-[40px] border-[1px] border-gray-3 rounded-[5px] p-[11px] bg-white absolute right-[17px] bottom-[62px] z-10"
                onClick={handleZoom(1)}>
                <Icon
                  type="plusRectangle"
                  className="cursor-pointer h-[16px] !bg-contain"
                />
              </button>
              <button
                aria-label="map-zoom-minus-button"
                className="h-[40px] w-[40px] border-[1px] border-gray-3 rounded-[5px] p-[11px] bg-white absolute right-[17px] bottom-[17px] z-10"
                onClick={handleZoom(-1)}>
                <Icon
                  type="minus"
                  className="cursor-pointer h-[16px] !bg-contain"
                />
              </button>
              {isShowFilterBar && (
                <FilterBar
                  selectedServiceTypes={selectedServiceTypes}
                  handleClick={handleSelectedServiceTypes}
                  productFamilies={productFamilies}
                />
              )}
              <div className="fixed w-[100%] h-[100%] top-0 left-0 bg-black opacity-50 z-0" />
              {isLoaded && mapcontainer()}
            </div>
          )}
          {!isFullsize && (
            <>
              {isLoaded && mapcontainer()}
              <button
                style={{ right: isHome ? 40 : 10 }}
                // eslint-disable-next-line max-len
                className={`h-[25px] w-[25px] border-[1px] border-gray-3 rounded-1 p-1.5 bg-white absolute ${mapButtons}`}
                onClick={handleIsFullsize}
                aria-label="map-fullsize-button"
                data-cy="map-fullsize-button">
                <Icon
                  type="expand"
                  className="fill-indigo cursor-pointer h-[11px]"
                />
              </button>
              {isHome && (
                <div
                  className={`h-[25px] w-[25px] border-[1px] border-gray-3 rounded-1 p-[8.5px] bg-white absolute
                right-2.5 ${mapButtons}`}>
                  <CollapseButton
                    classNames=""
                    testId="mapWidget"
                    collapse={collapse}
                    setCollapse={setCollapse}
                  />
                </div>
              )}
              {isHome && collapse && (
                <MapTable
                  handleAllClick={handleAllClick}
                  inventoryAssets={inventoryAssets}
                  checkMapWidth={checkMapWidth}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
