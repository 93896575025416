import { Attachment } from '../../../types';
import { DetailViewCard } from '../CardWithTitle';
import { AttachmentDownloadBtn } from '../AttachmentDownloadBtn';
import { AttachmentDeleteBtn } from '../DeleteBtn/AttachmentDeleteBtn';
import { DocumentPreviewer } from '../DocumentPreviewer';
import { PermissibleRender } from '../PermissibleRender';
import {
  getAttachmentsLoadingSubject,
  getAttachmentsUploadingSubject,
  useAttachments
} from '../../../Api/Attachments/useAttachments';
import { useEffect, useState } from 'react';
import { Permission } from '../../../store/GeneralStore';
import { getSubject } from '../../../store/Attachment';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { AttachmentTableCTA } from '../AttachmentCTA/AttachmentTableCTA';
import { getRoute1Subject } from '../../../store/Route1';

export function AttachmentTable({ id }: { id: string }) {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [uploadedAttachments, setUploadedAttachments] = useState<Attachment[]>([]);
  const { downloadAttachment, deleteAttachment, setParentId, fetch, uploadAttachments } = useAttachments();

  useEffect(() => {
    const attachmentSub = getSubject().subscribe(({ attachments }) => {
      setUploadedAttachments(attachments.filter((a) => a.parentId === id));
    });
    const attachmentLoadingSub = getAttachmentsLoadingSubject().subscribe((loading) => setIsFetching(loading));
    const attachmentUploadingSub = getAttachmentsUploadingSubject().subscribe((uploading) => setUploading(uploading));
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && fetch(id));

    return () => {
      if (attachmentSub) attachmentSub.unsubscribe();
      if (attachmentLoadingSub) attachmentLoadingSub.unsubscribe();
      if (attachmentUploadingSub) attachmentUploadingSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) {
      setParentId(id);
      fetch(id);
    }
  }, [id]);

  const handleAddAttachments = (files: File[]) => {
    uploadAttachments(files);
    setCollapsed(false);
  };

  const cardActions = () => {
    return (
      <PermissibleRender requiredPermissions={[Permission.ADD_ATTACHMENTS_INVENTORY]}>
        <AttachmentTableCTA addAttachments={handleAddAttachments} />
      </PermissibleRender>
    );
  };

  return (
    <DetailViewCard
      cardActions={cardActions()}
      collapsed={collapsed}
      enableCollapse
      title={`Attachments (${uploadedAttachments?.length || 0})`}
      childrenWrapperClass="overflow-x-auto">
      <>
        {(isFetching || uploading) && <ComponentSpinner />}
        {!isFetching && !uploading && !uploadedAttachments.length && <div className="text-center">No Attachments</div>}
        {!isFetching && !uploading && !!uploadedAttachments.length && (
          <table className="w-full [&_tr]:border-y">
            <thead>
              <tr className="text-indigo-mid text-xs text-left [&>*]:font-normal [&>*]:p-2">
                <th className="border-r">File Name</th>
                <th>Upload Date</th>
                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {uploadedAttachments
                ?.filter((a) => a.parentId === id)
                ?.map((attachment) => (
                  <tr
                    key={`${attachment.name}-${attachment.id}`}
                    className="text-black text-sm text-left font-normal [&>*]:p-[0.65rem]">
                    <td className="border-r min-w-[10rem]">
                      <DocumentPreviewer
                        id={attachment.id}
                        name={attachment.name}
                        extension={attachment.fileExtension}
                        downloadAttachment={downloadAttachment}
                      />
                      {attachment.name}
                    </td>
                    <td className="border-r">{new Date(attachment.uploadDate).toLocaleDateString()}</td>
                    <td className="border-r">
                      <AttachmentDownloadBtn
                        attachmentId={attachment.id}
                        name={attachment.name}
                        downloadAttachment={downloadAttachment}
                      />
                    </td>
                    <td>
                      <PermissibleRender requiredPermissions={[Permission.ADD_TICKETS_ATTACHMENTS]}>
                        <AttachmentDeleteBtn
                          attachmentId={attachment.id}
                          deleteAttachment={deleteAttachment}
                        />
                      </PermissibleRender>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </>
    </DetailViewCard>
  );
}
