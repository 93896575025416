import { useEffect, useState } from 'react';
import { PageLayout } from '../../PageLayout';
import { Tab, Tabs, useTheme } from '@mui/material';
import { PortalUserData } from '../../../types';
import { isTeamManager, getUser, isAdmin, isSupport } from '../../../store/User';
import { getPortalUserSubject, usePortalUsers } from '../../../store/PortalUser';
import { TeamTab } from './Tabs/TeamTab';
import { Team, getTeamsSubject, useTeamsStore } from '../../../store/Team';
import { useAccountCustomizationsStore } from '../../../store/AccountCustomization';
import { useRoles } from '../../../store/Role';
import { buildAccountData, useAccounts } from '../../../store/Accounts';
import { UserTab } from './Tabs/UserTab';
import { AccountsTab } from './Tabs/AccountsTab';
import { getGeneralStoreSubject, updateState } from '../../../store/GeneralStore';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { TabPanel } from '../../Atoms/TabPanel';
import { Route1Toggle } from '../../Compounds/Route1Toggle';
import { getRoute1Subject } from '../../../store/Route1';

export const AdminPage = () => {
  const user = getUser();
  const page = isAdmin(user) || isSupport(user) ? 'Admin' : 'Team Settings';
  const { getPortalUsers } = usePortalUsers();
  const { fetchTeams } = useTeamsStore();
  const { fetchRoles } = useRoles();
  const { fetchAccounts } = useAccounts();
  const [portalUsers, setPortalUsers] = useState<PortalUserData[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const { fetchAccountCustomizations } = useAccountCustomizationsStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const [adminLoading, setAdminLoading] = useState(false);
  const theme = useTheme();

  const tabs = isTeamManager(user)
    ? [{ name: 'Users', component: <UserTab portalUsers={portalUsers} /> }]
    : [
        { name: 'Users', component: <UserTab portalUsers={portalUsers} /> },
        { name: 'Teams', component: <TeamTab teams={teams} /> },
        { name: 'Accounts', component: <AccountsTab /> }
      ];

  const initAdminData = async () => {
    setAdminLoading(true);
    await Promise.all([fetchAccounts(), fetchRoles(), fetchAccountCustomizations(), getPortalUsers()]);

    // Account data depends on accounts, customizations, and portal users.
    buildAccountData();

    // Wait to fetch teams, depends on accounts.
    await fetchTeams();
    setAdminLoading(false);
  };

  useEffect(() => {
    const portalUsersSub = getPortalUserSubject().subscribe((portalUsersData) => setPortalUsers(portalUsersData));
    const teamsSub = getTeamsSubject().subscribe((teams) => setTeams(teams));
    const generalStateSub = getGeneralStoreSubject().subscribe(({ admin }) => {
      setSelectedTab(admin.selectedTab);
    });
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && initAdminData());

    initAdminData();

    return () => {
      if (portalUsersSub) portalUsersSub.unsubscribe();
      if (teamsSub) teamsSub.unsubscribe();
      if (generalStateSub) generalStateSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    updateState({ admin: { selectedTab: index } });
  };

  return (
    <PageLayout
      dataCy="admin-page-card"
      pageTitle={page}>
      <Route1Toggle />
      <>
        <Tabs
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1.5rem',
            backgroundColor: 'rgb(247 247 247)',
            borderBottom: '1px solid rgb(229 231 235)',
            borderTop: '1px solid rgb(229 231 235)'
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              height: selectedTab <= tabs.length - 1 ? 2 : 0,
              borderRadius: 1
            }
          }}
          value={selectedTab}
          onChange={(_e, index) => handleTabChange(index)}>
          {tabs.map(({ name }, index) => (
            <Tab
              data-cy={`tab-link-${name}`}
              sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
              key={index}
              label={name}
            />
          ))}
        </Tabs>
        {adminLoading && <ComponentSpinner sx={{ marginTop: '15rem' }} />}
        {!adminLoading &&
          tabs.map(({ component }, index) => (
            <TabPanel
              key={`${index}`}
              index={index}
              value={selectedTab}>
              {component}
            </TabPanel>
          ))}
      </>
    </PageLayout>
  );
};
