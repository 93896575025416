import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Attachment } from '../../types';
import { initAttachment } from './useAttachmentsHelpers';
import { v4 } from 'uuid';
import { uploadAttachment } from './uploadAttachment';
import { downloadAttachment as apiDownloadAttachment } from './downloadAttachment';
import { deleteAttachment as apiDeleteAttachment } from './deleteAttachment';
import {
  addQueuedAttachments,
  addAttachments,
  cancelQueuedAttachment,
  getSubject,
  getAttachmentsByParentId,
  removeAttachment,
  updateAttachmentState,
  AttachmentState
} from '../../store/Attachment';
import { useSnackbar } from '../../Context/SnackbarContext';
import { fetchAttachments } from './fetchAttachments';
import { addError } from '../../store/Error';
import { BehaviorSubject } from 'rxjs';

export interface AttachmentReturn {
  queuedAttachments: Attachment[];
  uploadedAttachments: Attachment[];
  uploadAttachments: (files: File[]) => void;
  cancelUpload: (attachmentId: string) => void;
  retryUpload: (attachmentId: string) => void;
  downloadAttachment: (attachmentId: string) => Promise<string | null | undefined>;
  deleteAttachment: (attachmentId: string) => Promise<boolean | undefined>;
  upload: () => void;
  fetch: (id: string) => Promise<void>;
  setParentId: Dispatch<SetStateAction<string>>;
}

export interface AttachmentUploadResponse {
  message: string;
  id: string;
}

const emitErrorState = (message?: string): void => addError('attachments', message);
const loadingSubject = new BehaviorSubject<boolean>(false);
const uploadingSubject = new BehaviorSubject<boolean>(false);

export const getAttachmentsLoadingSubject = (): BehaviorSubject<boolean> => loadingSubject;
export const getAttachmentsUploadingSubject = (): BehaviorSubject<boolean> => uploadingSubject;

export function useAttachments(): AttachmentReturn {
  const [parentId, setParentId] = useState<string>('');
  const [queuedAttachments, setQueuedAttachments] = useState<Attachment[]>([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<Attachment[]>([]);
  const { setSnack } = useSnackbar();

  const uploadAttachments = (files: File[]) => {
    const newQueuedAttachments = files?.map((file) => initAttachment(file, v4(), parentId));
    if (newQueuedAttachments) addQueuedAttachments(newQueuedAttachments);
  };

  const cancelUpload = (attachmentId: string) => {
    const attachment = queuedAttachments?.find((a) => a.id === attachmentId);
    if (attachment) {
      attachment.cancelSource.cancel();
      cancelQueuedAttachment(attachmentId);
    }
  };

  const retryUpload = (attachmentId: string) => {
    const newQueuedAttachments = [...queuedAttachments];
    const attachmentIndex = newQueuedAttachments?.findIndex((a) => a.id === attachmentId);

    // Re-initialize attachment to update all fields.
    const newAttachmentObj = initAttachment(newQueuedAttachments[attachmentIndex].file, attachmentId, parentId);
    newQueuedAttachments[attachmentIndex] = newAttachmentObj;

    updateAttachmentState({ queuedAttachments: newQueuedAttachments });
  };

  const downloadAttachment = async (attachmentId: string): Promise<string | null | undefined> => {
    const response = await apiDownloadAttachment(attachmentId);
    if (response.error || response.data?.size === 0) return null;

    return URL.createObjectURL(response.data);
  };

  const deleteAttachment = async (attachmentId: string): Promise<boolean | undefined> => {
    const response = await apiDeleteAttachment(attachmentId);

    if (response.error) {
      return false;
    }

    removeAttachment(attachmentId);
    setUploadedAttachments(getAttachmentsByParentId(parentId));
    return true;
  };

  const updateQueuedAttachments = (a: Attachment) => {
    const attachmentUpdate = [...queuedAttachments];
    const attachmentIndex = attachmentUpdate.indexOf(a);
    attachmentUpdate[attachmentIndex] = a;
    updateAttachmentState({ queuedAttachments: attachmentUpdate });
  };

  const upload = async () => {
    queuedAttachments
      ?.filter((queuedAttachment) => queuedAttachment.status === 'queued')
      ?.map(async (attachment) => {
        if (attachment.file && parentId) {
          uploadingSubject.next(true);
          attachment.status = 'uploading';
          updateQueuedAttachments(attachment);

          const progressHandler = (progressEvent: { loaded: number; total: number }) => {
            const { loaded, total } = progressEvent;
            // Stop at 95 to account for SF upload on server side.
            attachment.progress = Math.min(Math.round((loaded / total) * 100), 95);
            updateQueuedAttachments(attachment);
          };

          const res = await uploadAttachment(attachment, progressHandler, parentId);

          if (res.error) {
            const isCancel = res.error.message === 'canceled';
            attachment.status = isCancel ? 'canceled' : 'error';
          } else {
            attachment.progress = 100;
            attachment.status = 'success';
            attachment.id = res.data.id;
            attachment.parentId = parentId;
            addAttachments([attachment]);
            setUploadedAttachments(getAttachmentsByParentId(parentId));
            setSnack({ message: 'Your attachments have been uploaded successfully', type: 'success', open: true });
          }

          updateQueuedAttachments(attachment);
          uploadingSubject.next(false);
        }
      });
  };

  const fetch = async (id: string) => {
    if (!id) return;

    emitErrorState();
    loadingSubject.next(true);
    setUploadedAttachments([]);
    updateAttachmentState({ queuedAttachments: [] });

    const relatedAttachments = getAttachmentsByParentId(id);
    if (relatedAttachments?.length) {
      setUploadedAttachments(relatedAttachments);
    } else {
      const { error, data } = await fetchAttachments(id);

      if (error) emitErrorState(error.message);

      if (data) {
        addAttachments(data);
        setUploadedAttachments(getAttachmentsByParentId(id));
      }
    }

    loadingSubject.next(false);
  };

  // Upload attachments on file select
  useEffect(() => {
    const takeAction = async () => {
      upload();
    };
    takeAction();
  }, [queuedAttachments]);

  useEffect(() => {
    const attachmentSubscription = getSubject().subscribe(({ queuedAttachments }: AttachmentState) => {
      setQueuedAttachments(queuedAttachments);
    });

    return () => {
      if (attachmentSubscription) attachmentSubscription.unsubscribe();
    };
  }, []);

  return {
    queuedAttachments,
    uploadedAttachments,
    uploadAttachments,
    cancelUpload,
    retryUpload,
    downloadAttachment,
    deleteAttachment,
    upload,
    fetch,
    setParentId
  };
}
