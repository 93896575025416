import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { format } from 'date-fns';
import { allOperators, standardOperators } from '../../Compounds/UpstackDataGrid/helpers';
import { getDate } from '../../../utils/helpers';
import { getAccountName } from '../../../store/Accounts';

export const DefaultTicketGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: '',
    sortable: false,
    width: 65,
    type: 'singleSelect',
    valueOptions: [],
    filterable: false,
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'sfId',
    headerName: 'Id',
    sortable: true,

    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'account_id',
    headerName: 'Account',
    sortable: true,
    width: 300,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return getAccountName(value);
    },
    valueGetter: (value, row) => getAccountName(row?.account_id),
    disableReorder: true,
    filterable: true,
    filterOperators: standardOperators()
  },
  {
    field: 'caseNumber',
    headerName: 'Case Number',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'subject',
    headerName: 'Subject',
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'additionalRecipients',
    headerName: 'Additional Recipients',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'ticketType',
    headerName: 'Ticket Type',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'issue',
    headerName: 'Issue',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    width: 75,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'submittedBy',
    headerName: 'Submitted By',
    sortable: true,
    width: 380,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'dateTimeOpened',
    headerName: 'Date/Time Opened',
    sortable: true,
    width: 180,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'dateTimeClosed',
    headerName: 'Date/Time Closed',
    sortable: true,
    width: 180,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplierTicketNumber',
    headerName: 'Supplier Ticket #',
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];
