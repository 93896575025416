import { useEffect, useState } from 'react';
import { PageLayout } from '../../PageLayout';
import { getAccounts, getAccountScope, getUser } from '../../../store/User';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets';
import { SummativeWidget } from '../../Compounds/SummativeWidget';
import { getInventorySubject, useInventory } from '../../../store/Inventory';
import { getTicketSubject, useSupportTickets } from '../../../store/SupportTicket';
import { InventoryAsset, SupportTicketsItem } from '../../../types';
import { combineLatest } from 'rxjs';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import { AccountModal } from '../../Compounds/Modals/AccountModal';
import { getRoute1Subject } from '../../../store/Route1';

export const AccountHomePage = () => {
  const accounts = getAccounts();

  // Only show modal if user has more than one account.
  if (!getAccountScope()?.length && accounts?.length) {
    if (accounts.length > 1) return <AccountModal />;
  }

  const [inventoryAssets, setInventoryAssets] = useState<InventoryAsset[]>([]);
  const [tickets, setTickets] = useState<SupportTicketsItem[]>([]);
  const [isShowCustomizeDrawer, setIsShowCustomizeDrawer] = useState(true);
  const { fetchTickets } = useSupportTickets();
  const { fetchInventory } = useInventory();
  const user = getUser();

  const initAppData = (forceRefresh = false) => {
    fetchTickets(forceRefresh);
    fetchInventory(forceRefresh);
  };

  useEffect(() => initAppData(), []);

  useEffect(() => {
    // TODO: temporary changes
    setIsShowCustomizeDrawer(!['Sephora USA', 'STEVEN MADDEN LTD'].includes(user.account_name || ''));
  }, [user?.account_name]);

  useEffect(() => {
    const dataSubscription = combineLatest({
      inventory: getInventorySubject(),
      tickets: getTicketSubject()
    }).subscribe(({ inventory, tickets }) => {
      setInventoryAssets(inventory), setTickets(tickets);
    });
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     * Keeping this as a separate subscription than the above data subscription for ease of cleanup
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && initAppData(true));

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  const pageActions = () => <>{isShowCustomizeDrawer && <CustomizeDashboardDrawer title="Home" />}</>;

  return (
    <PageLayout
      dataCy="home-container"
      pageTitle="Home"
      pageActions={pageActions()}>
      <>
        <SummativeWidget inventoryAssets={inventoryAssets} />
        <div className="flex flex-wrap text-center pb-20">
          <DashboardWidgets
            inventoryAssets={inventoryAssets}
            pageTitle="Home"
            tickets={tickets}
          />
        </div>
      </>
    </PageLayout>
  );
};
