import {
  GridFilterItem,
  GridFilterOperator,
  GridSingleSelectColDef,
  getGridSingleSelectOperators,
  getGridStringOperators,
  getGridBooleanOperators
} from '@mui/x-data-grid-pro';
import { Commission, InventoryAsset, Order, SupportTicketsItem, User } from '../../../types';
import { GridPreferences, PrefKey, getCurrentPreferences, getUser } from '../../../store/User';
import { getValuesForColumn } from '../../../utils/helpers';
import { SKIP_ACCOUNT_ROLES } from '../../../store/Role';
import { Team } from '../../../store/Team';
import { InputNumberInterval } from './BetweenFilterInput';
import { AutoCompleteFilter } from './AutoComplete';
import { AccountData } from '../../../store/Accounts';

export const getGridColumns = (
  data: SupportTicketsItem[] | Order[] | User[] | Team[] | AccountData[] | Commission[] | InventoryAsset[],
  defaultCols: GridSingleSelectColDef[],
  grid: string
): GridSingleSelectColDef[] => {
  const currentSettings = getCurrentPreferences();
  const user = getUser();
  const gridPrefs = currentSettings.content[grid as PrefKey] as GridPreferences;

  return defaultCols.reduce((acc: GridSingleSelectColDef[], col: GridSingleSelectColDef) => {
    const filterValues = getValuesForColumn(data, col.field);

    if (Array.isArray(filterValues)) col.valueOptions = filterValues;
    const idVisibleRoles = SKIP_ACCOUNT_ROLES;
    const hasRole = user?.roles?.some((role) => idVisibleRoles.includes(role.name));
    const idRoleCheck = (hasRole && col.field === 'id') || col.field !== 'id';
    const sfIdRoleCheck = (hasRole && col.field === 'sfId') || col.field !== 'sfId';
    const accountCheck = (user?.accounts?.length > 1 && col.field === 'account_id') || col.field !== 'account_id';

    if (!gridPrefs?.disabledFields?.includes(col?.field) && idRoleCheck && sfIdRoleCheck && accountCheck) {
      acc.push(col);
    }

    return acc;
  }, []);
};

const betweenOperator: GridFilterOperator = {
  label: 'between',
  value: 'between',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
      return null;
    }
    if (!filterItem.value[0] || !filterItem.value[1]) {
      return null;
    }
    return (value): boolean => {
      return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
    };
  },
  InputComponent: InputNumberInterval
};

export const standardOperators = (): GridFilterOperator[] => {
  const isEmpty = getGridStringOperators().find((op) => op.value === 'isEmpty') as GridFilterOperator;
  const allOperators = [...getGridSingleSelectOperators(), isEmpty];

  allOperators.forEach((o) => {
    if (o?.value && !['between', 'isEmpty', 'isAnyOf'].includes(o?.value)) {
      o.InputComponent = AutoCompleteFilter;
    }
  });

  return allOperators;
};

export const booleanOperators = (): GridFilterOperator[] => {
  return getGridBooleanOperators();
};

export const allOperators = (): GridFilterOperator[] => {
  return [...standardOperators(), betweenOperator];
};
