import { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Permission } from '../../../store/GeneralStore';
import { PermissibleRender } from '../PermissibleRender';
import { getRoute1Enabled, getRoute1Subject, toggleRoute1 } from '../../../store/Route1';

const enum Route1Text {
  ENABLE = 'Enable Route1',
  DISABLE = 'Disable Route1'
}

export const Route1Toggle = () => {
  const [route1Enabled, setRoute1Enabled] = useState(getRoute1Enabled());
  const [text, setText] = useState<Route1Text>(Route1Text.ENABLE);
  const handleToggleChanged = () => {
    toggleRoute1();
  };

  useEffect(() => {
    const sub = getRoute1Subject().subscribe((enabled) => setRoute1Enabled(enabled));

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setText(route1Enabled ? Route1Text.DISABLE : Route1Text.ENABLE);
  }, [route1Enabled]);

  return (
    <PermissibleRender requiredPermissions={[Permission.VIEW_ADMIN]}>
      <FormGroup className="w-[150px]">
        <FormControlLabel
          label={text}
          control={
            <Switch
              sx={{
                '.Mui-checked': {
                  color: '#2E01A4 !important'
                },
                '.MuiSwitch-track': {
                  backgroundColor: '#878EBE !important'
                }
              }}
              size="small"
              id="route1-toggle"
              checked={route1Enabled}
              onChange={handleToggleChanged}
            />
          }
        />
      </FormGroup>
    </PermissibleRender>
  );
};
