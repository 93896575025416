import { useEffect, useState } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE, RAISED_DIALOG } from '../../../utils/styleHelpers';
import { TextSpan } from '../../Atoms/Text';
import { PageLayout } from '../../PageLayout';
import { useAccounts } from '../../../store/Accounts';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../Atoms/Icon';
import { IconButton } from '@mui/material';
import { useRoles } from '../../../store/Role';
import { UserFormProps, usePortalUsers } from '../../../store/PortalUser';
import { addForm, clearForm, removeForm, getFormsValidSubject, getFormsSubject } from '../../../store/BulkForm';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { SettingsModal } from './SettingsModal';
import { UserPreferences, adminUrlForRole } from '../../../store/User';
import { ExitWizardConfirmation } from '../../Compounds/Modals/ExitConfirmation';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { getRoute1Subject } from '../../../store/Route1';

export interface SettingsModalData {
  customizations: UserPreferences;
  id: string;
  username: string;
}

export const BulkUserCreationPage = () => {
  const { fetchRoles, rolesLoading } = useRoles();
  const { setSnack } = useSnackbar();
  const { fetchAccounts, getAccountContacts, getSfUsers, accountsLoading } = useAccounts();
  const { createUsers } = usePortalUsers();
  const navigate = useNavigate();
  const [forms, setForms] = useState<UserFormProps[]>([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [settingModalData, setSettingModalData] = useState<SettingsModalData>();
  const [formsValid, setFormsValid] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [creatingUsers, setCreatingUsers] = useState<boolean>(false);
  const [showRemove, setShowRemove] = useState<boolean>(true);
  const showLoading = [creatingUsers, accountsLoading, rolesLoading].some((loadingState) => loadingState);

  useEffect(() => {
    fetchAccounts();
    getAccountContacts();
    getSfUsers();
    fetchRoles();

    const formsValidSub = getFormsValidSubject().subscribe((state) => setFormsValid(state));
    const formDataSub = getFormsSubject().subscribe((data) => {
      setForms(data as UserFormProps[]);
      setShowRemove(data.length > 1);
    });
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => {
      if (enabled) {
        fetchAccounts(true);
        getAccountContacts();
        getSfUsers();
      }
    });

    if (!forms.length) addForm('user');

    return () => {
      if (formsValidSub) formsValidSub.unsubscribe();
      if (formDataSub) formDataSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
      clearForm();
    };
  }, []);

  useEffect(() => {
    const ele = document.getElementById(forms[forms?.length - 1]?.id);
    if (ele && ele.scrollIntoView) ele.scrollIntoView();
  }, [forms.length]);

  const handleExit = () => navigate(adminUrlForRole());

  const handleAddForm = () => {
    if (forms?.length < 10) addForm('user');
    else
      setSnack({
        message: 'Only ten users can be created at one time.',
        open: true,
        type: 'warning'
      });
  };

  const handleSubmit = async () => {
    setCreatingUsers(true);
    await createUsers();
    setCreatingUsers(false);
    navigate(`${adminUrlForRole()}/new-user/summary`);
  };

  const page = adminUrlForRole() == '/admin' ? 'Admin' : 'Team Settings';

  return (
    <PageLayout pageTitle={page}>
      {showLoading && (
        <div className="mt-[30vh]">
          <ComponentSpinner label="Loading Form..." />
        </div>
      )}
      {!showConfirm && !showLoading && (
        <div className={`flex flex-col h-5/6 px-14 mt-6 ${RAISED_DIALOG}`}>
          <div className="border-b-2 px-2 w-full">
            <TextSpan
              size="lg"
              dataCy="bulk-user-form-title"
              dataTestId="bulk-users-title">
              Create New Users
            </TextSpan>
          </div>
          <div className="overflow-auto max-h-3/4 min-h-1/4">
            {forms?.map((form, i) => {
              return (
                <div
                  id={form.id}
                  className="flex w-full my-4"
                  key={form.id}>
                  {form.ui_element}
                  <div className="flex self-center lg:self-start items-center ml-auto">
                    <IconButton
                      onClick={() => {
                        setSettingModalData({
                          customizations: form.userCustomizations,
                          id: form.id,
                          username: `${form.first_name} ${form.last_name}`
                        });
                        setSettingsOpen(true);
                      }}>
                      <SettingsIcon />
                    </IconButton>
                    {showRemove && (
                      <div className="border-l-2">
                        <IconButton
                          color="warning"
                          onClick={() => removeForm(form?.id || '')}>
                          <RemoveCircleIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pb-5">
            <button
              className="flex items-center space-x-2"
              onClick={handleAddForm}
              data-cy="new-user">
              <Icon
                type="plus"
                className="p-2"
              />
              <TextSpan
                color="indigo"
                size="sm14">
                Add Additional User
              </TextSpan>
            </button>
          </div>

          <div className="flex mt-auto justify-between border-t-2 pt-4 self-end w-full">
            <button
              className={DEFAULT_CANCEL_BUTTON_STYLE}
              data-testid="cancel-button"
              data-cy="bulk-user-form-cancel-btn"
              onClick={() => setShowConfirm(true)}>
              Cancel
            </button>
            <button
              data-testid="add-users-button"
              className={DEFAULT_ADVANCE_BUTTON_STYLE}
              data-cy="add-users-btn"
              onClick={handleSubmit}
              disabled={!formsValid}>
              Add Users
            </button>
          </div>
        </div>
      )}
      {showConfirm && (
        <div className="flex h-5/6 justify-items-center">
          <ExitWizardConfirmation
            handleClose={handleExit}
            handleProceed={() => setShowConfirm(false)}
          />
        </div>
      )}
      {settingModalData && (
        <SettingsModal
          open={settingsOpen}
          setOpen={setSettingsOpen}
          userName={settingModalData.username}
          userPreferences={settingModalData.customizations}
          id={settingModalData.id}
        />
      )}
    </PageLayout>
  );
};
