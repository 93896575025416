import { useState, useEffect } from 'react';
import { SupportTicketsItem } from '../../../types';
import { SelectMenu } from '../../Atoms/SelectMenu';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { ticketsChartData, monthsOptions, getFirstAndLastDayOfMonth } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { BORDER_STYLE } from '../../../utils/styleHelpers';
import { getTicketLoadingSubject } from '../../../store/SupportTicket';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { TextBlock } from '../../Atoms/Text';
import { v4 } from 'uuid';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { UserPreferences, getCurrentPreferences, setPreferences } from '../../../store/User';

export interface TicketsWidgetProps {
  tickets: SupportTicketsItem[];
  barWidth?: number;
  isHome?: boolean;
}

const getDateFilter = (startDate: Date, endDate: Date): GridFilterItem => ({
  id: v4(),
  field: 'dateTimeOpened',
  value: [startDate.getTime(), endDate.getTime()],
  operator: 'between'
});

const updatePreferences = (filter: GridFilterItem[]) => {
  const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));
  if (update.content.ticket_grid.muiConfig.filter?.filterModel) {
    update.content.ticket_grid.muiConfig.filter.filterModel.items = filter;
  }
  setPreferences(update);
};

export function TicketsWidget({ tickets, barWidth, isHome }: TicketsWidgetProps) {
  const [months, setMonths] = useState<number>(12);
  const navigate = useNavigate();
  const data = ticketsChartData(tickets, months);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadingSub = getTicketLoadingSubject().subscribe((loading) => setTicketsLoading(loading));

    return () => {
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const handleOpenClick = (data: { name: string }) => {
    const [firstDay, lastDay] = getFirstAndLastDayOfMonth(data.name);
    const filterStatus: GridFilterItem[] = [
      {
        id: v4(),
        field: 'status',
        operator: 'not',
        value: 'Escalated'
      },
      {
        id: v4(),
        field: 'status',
        operator: 'not',
        value: 'Closed (Resolved/ Unconfirmed)'
      }
    ];

    updatePreferences([...filterStatus, getDateFilter(firstDay, lastDay)]);
    if (isHome) navigate('/support-ticketing');
  };
  const handleClosedClick = (data: { name: string }) => {
    const [firstDay, lastDay] = getFirstAndLastDayOfMonth(data.name);
    const filterStatus: GridFilterItem = {
      id: v4(),
      field: 'status',
      operator: 'is',
      value: 'Closed (Resolved/ Unconfirmed)'
    };

    updatePreferences([filterStatus, getDateFilter(firstDay, lastDay)]);
    if (isHome) navigate('/support-ticketing');
  };

  return (
    <div
      className={`${BORDER_STYLE} py-3`}
      data-cy="tickets-widget">
      <p
        aria-level={2}
        role="heading"
        className="text-sm font-medium tracking-[0.6px] text-grey-5 text-center leading-[15px]">
        TICKETS
      </p>
      {ticketsLoading && (
        <div className={`h-32 ${!isHome && 'w-64'} text-center py-14`}>
          <ComponentSpinner />
        </div>
      )}
      {!tickets.length && !ticketsLoading && (
        <div className={`h-32 ${!isHome && 'w-64'} text-center py-14`}>
          <TextBlock size="sm14">No data available</TextBlock>
        </div>
      )}
      {!ticketsLoading && tickets.length > 0 && (
        <>
          <div className="text-center mt-3 mb-2 widget-container m-auto text-[10px]">
            <BarChart
              width={isHome ? barWidth : 287}
              height={117}
              data={data}
              margin={{
                bottom: -10,
                left: -25,
                right: 20,
                top: 0
              }}>
              <CartesianGrid
                horizontalPoints={[0]}
                horizontal={false}
                stroke="#ccc"
                vertical={false}
              />
              <XAxis
                dataKey="name"
                interval="preserveStartEnd"
                padding="no-gap"
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                domain={[0, 'dataMax + 3']}
                tickLine={false}
              />
              <Tooltip />
              <Bar
                dataKey="Closed Tickets"
                fill="#2E01A4"
                onClick={handleClosedClick}
                stackId="a"
                data-testid="closed-bar"
              />
              <Bar
                dataKey="Open Tickets"
                fill="#E4DDF9"
                onClick={handleOpenClick}
                stackId="a"
                data-testid="open-bar"
              />
            </BarChart>
          </div>
          <hr />
          <div className="flex flex-row gap-x-[5px] mt-[5px]">
            <span className="dot bg-indigo mt-[10px] ml-[20px]" />
            <span className="text-grey-8 text-[10px] leading-[30px]">Closed</span>
            <span className="dot bg-grey-11 mt-[10px] ml-[10px]" />
            <span className="text-grey-8 text-[10px] leading-[30px]">Open</span>
            <div className="mx-3.5 w-full">
              <SelectMenu
                defaultValue={String(months)}
                handleSelect={(e) => setMonths(Number(e.target.value))}
                name="months"
                dataCy="months"
                options={monthsOptions}
                selectClasses="text-[10px]"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
