import { useEffect, useState } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE, RAISED_DIALOG } from '../../../utils/styleHelpers';
import { TextSpan } from '../../Atoms/Text';
import { PageLayout } from '../../PageLayout';
import { useAccounts } from '../../../store/Accounts';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../Atoms/Icon';
import { IconButton } from '@mui/material';
import {
  addForm,
  clearForm,
  removeForm,
  getFormsValidSubject,
  getFormsSubject,
  TeamFormProps
} from '../../../store/BulkForm';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { adminUrlForRole } from '../../../store/User';
import { ExitWizardConfirmation } from '../../Compounds/Modals/ExitConfirmation';
import { useTeamsStore } from '../../../store/Team';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { getRoute1Subject } from '../../../store/Route1';

export const BulkTeamCreationPage = () => {
  const { setSnack } = useSnackbar();
  const { fetchAccounts, accountsLoading } = useAccounts();
  const navigate = useNavigate();
  const [forms, setForms] = useState<TeamFormProps[]>([]);
  const [formsValid, setFormsValid] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [creatingTeams, setCreatingTeams] = useState<boolean>(false);
  const [showRemove, setShowRemove] = useState<boolean>(true);
  const { createTeams, fetchTeams } = useTeamsStore();
  const showLoading = [creatingTeams, accountsLoading].some((loadingState) => loadingState);

  useEffect(() => {
    fetchAccounts();

    const formsValidSub = getFormsValidSubject().subscribe((state) => setFormsValid(state));
    const formDataSub = getFormsSubject().subscribe((data) => {
      setForms(data as TeamFormProps[]);
      setShowRemove(data.length > 1);
    });
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && fetchAccounts(true));

    if (!forms.length) addForm('team');

    return () => {
      if (formsValidSub) formsValidSub.unsubscribe();
      if (formDataSub) formDataSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
      clearForm();
    };
  }, []);

  useEffect(() => {
    const ele = document.getElementById(forms[forms?.length - 1]?.id);
    if (ele && ele.scrollIntoView) ele.scrollIntoView();
  }, [forms.length]);

  const handleExit = () => navigate(adminUrlForRole());

  const handleAddForm = () => {
    if (forms?.length < 10) addForm('team');
    else
      setSnack({
        message: 'Only ten teams can be created at one time.',
        open: true,
        type: 'warning'
      });
  };

  const handleSubmit = async () => {
    setCreatingTeams(true);
    await createTeams();
    await fetchTeams();
    setCreatingTeams(false);
    navigate('/admin/new-team/summary');
  };

  return (
    <PageLayout pageTitle="Admin">
      {showLoading && <ComponentSpinner />}
      {!showConfirm && !showLoading && (
        <div className={`flex flex-col h-5/6 px-14 mt-6 ${RAISED_DIALOG}`}>
          <div className="border-b-2 px-2 w-full">
            <TextSpan
              size="lg"
              dataTestId="bulk-teams-title"
              dataCy="bulk-team-form-title">
              Create New Teams
            </TextSpan>
          </div>
          <div className="overflow-auto max-h-3/4 min-h-1/4">
            {forms?.map((form, i) => {
              return (
                <div
                  id={form.id}
                  className="flex w-full my-4"
                  key={form.id}>
                  {form.ui_element}
                  {showRemove && (
                    <div className="border-l-2 self-center mr-auto">
                      <IconButton
                        color="warning"
                        onClick={() => removeForm(form.id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="pb-5">
            <button
              className="flex items-center space-x-2"
              onClick={handleAddForm}
              data-cy="new-user">
              <Icon
                type="plus"
                className="p-2"
              />
              <TextSpan
                color="indigo"
                size="sm14">
                Add Additional Team
              </TextSpan>
            </button>
          </div>
          <div className="flex mt-auto justify-between border-t-2 pt-4 self-end w-full">
            <button
              className={DEFAULT_CANCEL_BUTTON_STYLE}
              data-testid="cancel-button"
              data-cy="bulk-team-form-cancel-btn"
              onClick={() => setShowConfirm(true)}>
              Cancel
            </button>
            <button
              data-testid="add-teams-button"
              className={DEFAULT_ADVANCE_BUTTON_STYLE}
              data-cy="bulk-team-form-add-teams-btn"
              onClick={handleSubmit}
              disabled={!formsValid}>
              Add Teams
            </button>
          </div>
        </div>
      )}
      {showConfirm && (
        <div className="flex h-5/6 justify-items-center">
          <ExitWizardConfirmation
            handleClose={handleExit}
            handleProceed={() => setShowConfirm(false)}
          />
        </div>
      )}
    </PageLayout>
  );
};
